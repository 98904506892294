import React from 'react'
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Espace from "./components/Espace";
import Centre from "./components/Centre";
import About from "./components/About";
import Footer from "./components/Footer";
import "./css/App.css";
import Position from "./components/Position";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  return (
    
    <div>
        <div id="home">
          <Navbar />
          <ToastContainer />

            <Hero />
            <Centre />
            <About />
            <Services />
            <Espace />
            <Position />
            <br />
            <br />
            <br />
            <Footer />
          {/* Add more routes here if needed */}
        </div>
    </div>
  )
}

export default App