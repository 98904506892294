import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import doc1 from "../assets/1.jpeg";
import doc2 from "../assets/2.jpeg";
import doc3 from "../assets/3.jpg";
import doc4 from "../assets/7.jpeg";
import doc5 from "../assets/8.jpeg";
import doc6 from "../assets/9.jpeg";

const Espace = () => {
  const data = [
    {
      img: doc1,
     
    },
    {
      img: doc2,
    
    },
    {
      img: doc3,
      
    },
    {
      img: doc4,
     
    },
    {
      img: doc5,
     
    },
    {
      img: doc6,
    
    },
  ];

  const slider = useRef(null);

  const settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div id="espace" className="min-h-screen flex flex-col justify-center lg:px-32 px-5 pt-16 text-[#70363d] bg-gradient-to-b from-[#edf4ff] to-[#f6f9ff]">
          <h1 className="text-4xl text-[#1b4899] font-bold  lg:text-start mx-auto">
            Gallerie
          </h1>
        <div className="mx-auto mb-4">
          <p className="mt-2 text-center lg:text-start font-bold text-[#001e55] ">
          Explorez notre centre, où le confort des patients, l'expertise médicale et la technologie de pointe se rencontrent pour vous offrir des soins de qualité supérieure dans un environnement chaleureux.
          </p>
        </div>
      <div className="flex flex-col items-center lg:flex-row justify-between mb-10 lg:mb-0">
        <div className="flex gap-3 mt-2 lg:mt-0 mx-auto ">
          <button
            className="bg-[#1b4899] text-[#1b4899] px-4 py-2 rounded-lg active:bg-[#a47f83] hover:bg-[#ffffff] group"
            onClick={() => slider.current.slickPrev()}
          >
            <FaArrowLeft size={25} className="text-[#ffffff] group-hover:text-[#1b4899]"/>
          </button>
          <button
            className="bg-[#1b4899] text-[#1b4899] px-4 py-2 rounded-lg active:bg-[#a47f83] hover:bg-[#ffffff] group"
            onClick={() => slider.current.slickNext()}
          >
            <FaArrowRight size={25} className="text-[#ffffff] group-hover:text-[#1b4899]"/>
          </button>
        </div>
      </div>
      <div className="mt-5 ">
        <Slider ref={slider} {...settings}>
        {data.map((e, index) => (
          <div
            className="h-[350px] text-stone-300 rounded-xl shadow-lg mb-2 cursor-pointer mx-2 flex justify-center items-center overflow-hidden"
            key={index}
          >
            <img
              src={e.img}
              alt={e.name}
              className="h-full w-full object-cover object-center"
            />
          </div>
        ))}
        
        </Slider>
      </div>
    </div>
  );
};

export default Espace;
