import React from "react";
import { Link } from "react-scroll";
import {AiOutlineGoogle,AiOutlineWhatsApp} from 'react-icons/ai'
import { FaFacebook } from 'react-icons/fa';


const Footer = () => {
  return (
    <div className=" bg-[#1b4899] rounded-t-3xl mt-8 md:mt-0  pb-1 text-red-50">
      <div className="flex flex-col md:flex-row justify-between p-8 md:px-32 px-5">
        <div className=" w-full md:w-1/4">
          <h1 className="text-2xl pb-4 font-bold text-[#ffffff]">Horaire:</h1>
          <p className=" text-m font-bold">
          Lun – Sam :ㅤ 6H00 –  21H00
          </p>
        </div>
       
        <div>
          <h1 className=" font-bold text-2xl pb-4 pt-5 md:pt-0 text-[#ffffff]">Nos Partenaires</h1>
          <nav className=" flex flex-col gap-2">
            <a
              href="https://ckd.ma/" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-slate-900" rel="noreferrer"
            >
              Centre Kilyati - Inezgane
            </a>
            <a
              href="https://dialyse44.com" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-slate-900" rel="noreferrer"
            >
              Clinique 44 - Ouled Teima
            </a>
            <a
              href="https://essaouira-dialyse.com" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-slate-900" rel="noreferrer"
            >
              Centre International - Essaouira
            </a>

          </nav>
        </div>
        <div className=" w-full md:w-1/4">
          <h1 className=" font-bold text-2xl pb-4 pt-5 md:pt-0 text-[#ffffff]">Contact Us</h1>
          <nav className=" flex flex-col gap-2 font-bold">
            <Link to="/" spy={true} smooth={true} duration={500}>
              N°166 BD MLY ABDELLAH LOT JAOUHARA BIOUGRA
            </Link>
            <Link to="/" spy={true} smooth={true} duration={500}>
              biodialyse@gmail.com
            </Link>
            <Link to="/" spy={true} smooth={true} duration={500} className=" font-bold">
              Tél: 06 60 62 08 29  │  Fax: 05 28 81 09 81 <br />
              Urgences: 07 78 36 18 73
            </Link>
          </nav>
          <br />
          <div className="flex mt-2 gap-4 items-center">
            <FaFacebook size={30} className="hover:text-slate-900 cursor-pointer"/>
            <AiOutlineGoogle size={38} className="hover:text-slate-900  cursor-pointer"/>
            <AiOutlineWhatsApp size={30} className="hover:text-slate-900 cursor-pointer"/>
          </div>
        </div>
      </div>
      <div>
        <br />
        <p className=" text-center">
        @copyright 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
