import React from 'react';
import { FaMapPin ,FaMapMarkedAlt} from 'react-icons/fa';

const Position = () => {
  return (
    <div className='flex flex-col items-center justify-center text-center p-8 pb-4 bg-gradient-to-b from-[#f6f9ff] to-[#ffffff]' >
      <div className='flex gap-2'>
        <FaMapPin size={40} className='text-[#1b4899]' />
        <h1 className='text-2xl font-bold text-[#1b4899] mb-4 shadow-md'>Notre Emplacement:</h1>
      </div>
      <p className='text-[#6d131d] font-bold m-2 shadow-sm'>
      Adresse : N°166 BD MLY ABDELLAH LOT JAOUHARA BIOUGRA
      </p>
      <div className='w-full md:w-3/4 lg:w-1/2 pb-[40%] relative'>
        <iframe
          className='rounded-lg ring-cyan-300 shadow-xl absolute top-0 left-0 w-full h-full'
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3447.761354310966!2d-9.3775901!3d30.215358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3c347055e3c1f%3A0xa3a9399becbc1358!2sCLINIQUE%20DU%20REIN!5e0!3m2!1sfr!2sma!4v1714123870273!5m2!1sfr!2sma"
          width="70%"
          height="70%"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title='Responsive Google map'
        />
      </div>
      <div className=' m-3 rounded-lg bg-[#1b4899] w-[300px] shadow-lg my-6 mx-auto p-2 text-white text-[20px] font-bold hover:text-[#ffffff] hover:bg-[#1b4899] hover:scale-105 transition-all hover:transiton'>
        <button className=' '>
          <a href="https://maps.app.goo.gl/QYseWaBicnq97jvVA" target="_blank" rel="noreferrer"> 
            Google Maps
            <FaMapMarkedAlt size={30} className='inline m-2 '/>
          </a>

        </button>
      </div>
    </div>
  );
};

export default Position;
