
import CentreCards from "../layouts/CentreCards";
import img1 from "../assets/blog1.jpg";
import img2 from "../assets/blog2.jpg";
import img3 from "../assets/blog3.jpg";



const Centre = () => {
  const handleCardClick = (url) => {
    window.open(url, '_blank');
  };

  const cardsData = [
    { img: img1, headlines: "Centre Kilyati - Inezgane", summary: "Le Centre d'Hémodialyse Kilyati est l'endroit idéal pour les touristes qui ont besoin de séances d'hémodialyse pendant leur séjour à Inezgane et ses environs. Notre centre offre des services de dialyse de haute qualité, assurés par une équipe médicale expérimentée et dévouée. Avec des installations modernes, un environnement accueillant et des soins personnalisés, nous vous invitons à choisir le Centre d'Hémodialyse Kilyati pour vos besoins en dialyse pendant votre séjour dans notre belle région.", url: "https://ckd.ma/" },


    { img: img2, headlines: "Ouled Teima (En cours de construction)", summary: "Notre centre d'hémodialyse est actuellement en cours de construction. Nous travaillons avec diligence pour créer un espace sûr, moderne et adapté à vos besoins médicaux. Chaque jour, nous progressons vers l'achèvement de ce projet important afin de pouvoir vous offrir des services de dialyse de haute qualité dans un environnement confortable et accueillant. Votre patience est grandement appréciée pendant cette période de préparation, et nous avons hâte de vousㅤㅤㅤㅤ accueillir bientôt dans notre nouveau centre.ㅤㅤㅤㅤㅤㅤㅤ ",url: "https://dialyse44.com" },


    { img: img3, headlines: "Centre International - Essaouira", summary: "Le Centre de Néphrologie et d’Hémodialyse International Essaouira est implanté dans une residence de haute standing au centre de la ville d’Essaouira et à 10 min de l’aéroport Mogador, notre centre vous accueille dans un environnement calme, doux et serein, favorisant le déroulement agréable de votre traitement.", url: "https://essaouira-dialyse.com"},


  ];

  return (
    <div className="min-h-screen flex flex-col justify-center items-center lg:px-32 px-5 bg-[#fbfeff]">
      <div>
        
        <h1 className="text-[#1b4899] text-4xl font-bold text-center">
        Vous Etes Dans Une Autre Ville ?
        </h1>
        <br />
       
      </div>

      <div className="my-8">
        <div className="flex flex-wrap justify-center gap-10">
          {cardsData.map((card, index) => (
            <CentreCards
              key={index}
              img={card.img}
              headlines={card.headlines}
              summary={card.summary}
              onClick={() => handleCardClick(card.url)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Centre;
